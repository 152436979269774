import "./styles.css";
import usePartySocket from "partysocket/react";
import { useState } from "react";
import { createRoot } from "react-dom/client";

function App() {
	const [latestMessage, setLatestMessage] = useState("");
	usePartySocket({
		room: "example-room",
		onMessage(evt) {
			setLatestMessage(evt.data);
			console.log("Received message:", evt.data);
		},
	});
	return (
		<div>
			<h1>🎈 Welcome to PartyKit!</h1>
			<p>Latest message: {latestMessage}</p>
		</div>
	);
}

createRoot(document.getElementById("app")!).render(<App />);
